import "./template.scss"

export default {
    name: "ve-template",
    props: {
        ud: {
            type: Object,
            default: function () { return {} }
        }
    },
    data() {
        return {
            loading: false,
            step: 1,
        }
    },
    computed: {
        test() {
            return []
        }
    },
    watch: {
        ud: function (newVal, oldVal) {
        }
    },
    methods: {
        steps() {
            return [
                { title: "" },
                { title: "" },
                { title: "" },
                { title: "" },
                { title: "", button: "Fortsätt till signering" }

            ]
        },
        nextStep() {
            if (!this.validateForm(`form${this.step}`)) {
                return
            }
            console.log("Step", this.step)
            if (this.step >= 5)
                return this.submit()
            this.step++
        },

        previousStep(n) {
            this.step = n
        },
        back(){
            if(this.step > 1){
                this.step--
            }
        },
        addOrder() {
            this.ud.orders.push({
                contract: null,
                articleNr: null,
                articleDesc: null,
                hazardInfo: null,
                palletType: null,
                ewc: null,
                packing: null,
                quantity: null,
                weight: null
            })
        },
        removeOrder() {
            if (this.ud.orders.length > 1) {
                this.ud.orders.pop()
            }
        },
        validateForm(form) {
            return this.$refs[form].validate()
        },

        async submit() {
            this.$emit('on-submit')
        }

    },
    async created() {
        console.log("Template created")
        this.loading = false
    }

};