import Vue from 'vue'
import components from '../components'
import { auth, envelopes } from '@verified/libvf'
export default {
    name: 'App',
    components: {
    },
	data() {
		return {
            loading: true,
            test: false,
            envelope: null,
            ud:{
                _isForwarded: false,
                company:{
                    sysav: false,
                    sysav_industri_ab: false
                },
                responsible: null,
                orderNr: null,
                date: null,
                recipient:{
                    name: null,
                    address: null,
                    zip: null,
                    city: null,
                    country: null,
                    phone: null,
                    orgNr: null,
                    reference: null
                },
                sender:{
                    name: null,
                    address: null,
                    zip: null,
                    city: null,
                    country: null,
                    phone: null,
                    orgNr: null,
                    municipality:null,
                    reference: null
                },
                transporter:{
                    name: null,
                    address: null,
                    zip: null,
                    city: null,
                    country: null,
                    orgNr: null,
                    date:null
                },
                orders:[
                    {
                        contractNr: null,
                        articleNr: null,
                        articleDesc: null,
                        hazardInfo: null,
                        palletType: null,
                        ewc: null,
                        packing: null,
                        quantity: null,
                        weight: null
                    }
                ],
                signatory:{
                    firstName: null,
                    lastName: null,
                    email: null
                }

            }   
		}
	},
	methods: {
        injectTestData() {
            Object.assign(this.ud, { 
                "company": { 
                    "sysav": false, 
                    "sysav_industri_ab": true 
                }, 
                "orderNr": "EO-20778431",
                "date": "2021-01-12", 
                "recipient": { 
                    "name": "Lösningsmedelscisterner", 
                    "address": "Sysav Industri AB, Spillepengsgatan 16", 
                    "zip": "21124", 
                    "city": "Malmö", 
                    "country": "SWE", 
                    "phone": null, 
                    "orgNr": null, 
                    "reference": null 
                }, 
                "sender": { 
                    "name": "7760 AkzonNbel Bindemedelsfabriken (Resins)", 
                    "address": "Staffanstorpsvägen 50", 
                    "zip": "23261", 
                    "city": "Arlöv", 
                    "country": "SWE", 
                    "phone": null, 
                    "orgNr": "5560359993", 
                    "municipality": "1280", 
                    "reference": null 
                },
                "transporter": { 
                    "name": "Puls AB", 
                    "address": "Verkstadsvägen 2", 
                    "zip": "24522", 
                    "city": "Staffanstorp", 
                    "country": "SWE", 
                    "orgNr": "5563791259", 
                    "date": "2021-01-12" 
                }, 
                "orders": [
                    { 
                        "contractNr": "47006", 
                        "articleNr": "Mt3310", 
                        "articleDesc": "Färg, flytande >20MJ/kg", 
                        "hazardInfo": "UN1263 AVFALL, Färgrelaterat material, 3, II\nTvätthinner, lösningsmedelbaserad tvättvätska, även lösningsmedelbaserade bindemedel", 
                        "palletType": "TANK", 
                        "ewc": "080111*", 
                        "packing": null, 
                        "quantity": "1", 
                        "weight": "1 000,00 Kg i Tank" 
                    }
                ], 
            })
        },
        async submit(){
            console.log("Submitted", this.ud)
            this.loading = true
            let self = this
            if(this.ud.company.sysav){
                this.ud.company = "sysav"
            }else{
                this.ud.company = "sysav_industri_ab"
            }
            let fileName = `Sysav_Transportdokument_${this.ud.orderNr}.pdf`
            envelopes.create("sysav-transport")
                .then((envelope) => {
                    console.log(envelope)
                    self.envelope = envelope
                }).then(() =>{
                    return self.envelope.addRecipient({
                        givenName: self.ud.signatory.firstName,
                        familyName: self.ud.signatory.lastName,
                        email: self.ud.signatory.email,
                        language: "sv_SE",
                        signingMethod: "touch-sign",
                        order: 1,
                        role: {
                            action: "sign",
                            label: "Signera som kund",
                            name: "signer"
                        }
                    })
                }).then(() =>{
                    return self.envelope.reflect()
                }).then(() =>{
                    return self.envelope.firstDocument().put( {name: fileName})
                }).then(() =>{
                    return self.envelope.firstTemplate().setUserData(self.ud)
                }).then(()=>{
                    return self.envelope.publish()
                }).then(()=>{
                    let recipientId = self.envelope.data.recipients[0].id
                    return self.envelope.getSignLink(recipientId)
                }).then((link) =>{
                    console.log("Link", link)
                    window.location.href = link
                    self.loading = false
                }).catch((err) =>{
                    console.warn("Error", err)
                    self.loading = false
                })
        }
	},
    async created() {
        console.log("App created")
        auth.useTokenFromUrl()
        Vue.use(components)
        this.$root.locale = 'sv_SE'
        this.setIso("sv_SE")
        if(this.test){
            this.injectTestData()
        }
        this.loading = false
    }    

}