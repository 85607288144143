import Vue from 'vue'
import App from './page/App.vue'
import veform from '@verified/veform'

import "./page/style.scss"

Vue.config.productionTip = false

Vue.use(veform)

new Vue({
  render: h => h(App),
}).$mount('#app')
